<template>
  <div class="main">
    <van-sticky>
      <van-search
          class="search"
          v-model="keyword"
          show-action
          shape="round"
          placeholder="请输入搜索关键词"
          @search="onSearch"
          ref="searchRef"
      >
        <template #action>
          <div @click="onSearch(keyword)">查询</div>
        </template>
      </van-search>
    </van-sticky>

    <div class="common-search">
      <div class="section">
        <p class="label">搜索历史</p>
        <div v-if="searchHistory.length>0">
          <span class="tag" v-for="item in searchHistory" :key="item" @click="quickSearch(item)">{{ item }}</span>
        </div>
        <div v-else class="empty">
          <span>没有搜索记录</span>
        </div>
      </div>
      <div class="section">
        <p class="label">热门搜索</p>
        <div>
          <span class="tag" v-for="item in hotList" :key="item.id" @click="quickSearch(item.content)">{{ item.content }}</span>
        </div>
      </div>
    </div>

    <div class="hot-search">
      <div class="section">
        <p class="label">搜索发现</p>
        <div class="hot-list" v-for="(item, index) in recommend" :key="item.id">
          <div class="row" @click="toProduct(item.id)">
            <svg class="iconfont" aria-hidden="true" v-if="index<3">
              <use xlink:href="#icon-hot"></use>
            </svg>
            <span class="num-tag" v-if="index > 2"></span>
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {
  onActivated,
  onMounted,
  ref,
  watch
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {Icon, Tag, Search, Sticky} from 'vant';
import searchRequest from '@/models/Search'

export default {
  setup() {
    const router = useRouter();

    const keyword = ref('');
    const active = ref('product');
    const finished = ref(false);
    const loading = ref(false);
    const inputKeyword = ref('')
    const searchHistory = ref([])
    const hotList = ref([])
    const recommend = ref([])

    const onSearch = (keyword) => {
      router.push('/product/search?keyword='+keyword)
    }
    const hotSearch = () => {
      searchRequest.hotSearch().then(res => {
        hotList.value = res.data.hot
        recommend.value = res.data.recommend
      })
    }
    onActivated( () => {
      const json = localStorage.getItem('searchHistory');
      searchHistory.value = json ? JSON.parse(json) : []
      hotSearch()
    })
    const quickSearch = (keyword, type) => {
      router.push({
        path: '/product/search',
        query: {
          'keyword': keyword,
          'type': type
        }
      })
    }
    const toProduct = (id) => {
      router.push('/product/product?id='+id)
    }
    const searchRef = ref(null);
    const setFocus = () => {
      searchRef.value.focus()
    }
    const route = useRoute()
    watch(() => route.path,(newVal, oldVal) => {
      if (oldVal === '/product/index') {
        setFocus()
      }
    })
    onMounted(() => {
      setFocus()
    })
    return {
      inputKeyword,
      keyword,
      active,
      finished,
      loading,
      searchHistory,
      hotList,
      recommend,
      searchRef,
      onSearch,
      quickSearch,
      toProduct
    };
  },
  components: {
    [Icon.name]: Icon,
    [Tag.name]:Tag,
    [Search.name]:Search,
    [Sticky.name]: Sticky,
  }
};
</script>
<style scoped>

.search {
  height: 80px;
}
.empty {
  text-align: center;
}
.common-search {
  margin-top: 8px;
}
.section {
  background-color: #fff;
  padding: 10px 15px;
}
.section .label {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 0;
  margin: 5px 0;
}
.section .tag {
  padding: 0 12px;
  border-radius: 15px;
  height: 30px;
  background-color: #f6f6f6;
  display: inline-flex;
  align-items: center;
  margin: 5px 15px 5px 0;
}
.hot-search {
  margin-top: 8px;
}

.hot-list .row {
  border-bottom: 1px solid #f6f6f6;
  height: 50px;
  line-height: 50px;
}
.hot-list .num-tag {
  background-color: #d0ecf5;
  width: 5px;
  height: 5px;
  padding: 2px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}
</style>
